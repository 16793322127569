.footer {
  position:fixed;
  width: 100%;
  bottom:0;
  @include xy-grid;
  justify-content: center;
  padding: .5rem;
  .copyright {
    text-align: center;
    @include xy-cell();
    @include breakpoint(medium) {
      @include xy-cell(8);
    }
  }
}
