.top-bar {
  .top-bar-right {
    flex: 5 5 auto;
  }
  .menu {
    .menu-text, a {
      color: $white;
      letter-spacing: .1rem;
      font-weight: normal;
    }
  }
}

.desktop-menu {
  a {
    margin: 0 2rem;
    text-align: center;
    letter-spacing: .2rem;
    div {
      position: relative;
      line-height: 1.6rem;
      &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 1px;
        content: "";
        background: $white;
        transition: left 0.45s, width 0.45s;
      }
      &:hover, &:focus {
        &::after {
          left: 20%;
          width: 60%;
          background: $white;
        }
      }
    }
  }

}

.mobile-menu {
  a {
    padding: 1rem;
    &:hover, &:focus {
      padding-left: 1.5rem;
      transition: padding-left 0.45s;
    }
    span {
      @extend .subtitle;
      font-size: .8rem;
      margin-left: .5rem;
    }
  }

}

// Navigation breakpoints
.mobile-menu {
   @include hide-for(medium);
}

.desktop-menu,
.top-bar .top-bar-left {
  @include show-for(medium);
}

.top-bar {
  @include breakpoint(small only) {
    // prevents container visibility on small screens
    padding: 0;
  }
}

// Mobile menu
.mobile-menu {
  display: none; // prevents repaint caused by JS hiding menu onload
}
