.container {
  @include xy-grid;
}
section {
    padding: 1rem 0;
  &:nth-child(odd) {
    // background-color: scale-color($light-gray, $lightness: 60%);
  }
  &:nth-child(even) {
    background-color: scale-color($light-gray, $lightness: 80%);
  }
  .section-header {
    @include xy-cell();
    @include breakpoint(large) {
      @include xy-cell(4);
    }
    padding: 1rem 0;
    h2 {
      letter-spacing: .2rem;
      margin-bottom: 0;
      color: $primary-color;
      @include breakpoint(large) {
        text-align: right;
      }
    }
    .subheader {
      margin-left: .5rem;
      @extend .subtitle;
      font-size: .9rem;
    }
  }
  .section-content {
    @include xy-cell();
    @include breakpoint(large) {
      @include xy-cell(8);
      padding-top: 5rem;
    }
  }
}

h1 {
  display: none;
}

#about dl, #services li {
  @include xy-grid;
}

#about dl dt, #services li::before {
  @include xy-cell(3);
  @include breakpoint(medium) {
    @include xy-cell(4);
  }
  @include breakpoint(large) {
    @include xy-cell(3);
  }
}

#about dl dd, #services li div {
  @include xy-cell(9);
  @include breakpoint(medium) {
    @include xy-cell(8);
  }
  @include breakpoint(large) {
    @include xy-cell(9);
  }
}

#about {
  dl {
    dt, dd {
      padding: .5rem;
    }
    dt {
      color: $primary-color;
      font-weight: normal;
      margin: 0;
      text-align: right;
      letter-spacing: .2rem;
    }
    dd {
      margin: 0;
    }
  }
}

#services {
  ul {
    list-style: none;
    margin: 0;
    li {
      padding: .5rem 0;
      &::before {
        @extend %fa-icon;
        @extend .fas;
        content: fa-content($fa-var-angle-right);
        color: scale-color($primary-color, $lightness: 50%);
        text-align: right;
        padding: .5rem;
        margin: 0;
      }
    }
  }
}
